import VanillaScroll from '../../node_modules/@spomsoree/vanilla-scroll/dist/vanilla-scroll.js';

const vs = new VanillaScroll({ debug: false });

vs.addTrigger({
    name:    'Debug',
    trigger: document.getElementById('debug'),
    steps:   [
        {
            name:     'Blur',
            element:  document.querySelector('h1'),
            offset:   30,
            duration: 60,
            change:   {
                filter: {
                    from: 'blur(0px)',
                    to:   'blur(20px)',
                },
            },
        },
        {
            name:     'Opacity (j)',
            element:  document.querySelector('h1 span:nth-child(1)'),
            offset:   54,
            duration: 20,
            change:   {
                opacity: {
                    from: 1,
                    to:   0,
                },
            },
        },
        {
            name:     'Opacity (o)',
            element:  document.querySelector('h1 span:nth-child(2)'),
            offset:   56,
            duration: 20,
            change:   {
                opacity: {
                    from: 1,
                    to:   0,
                },
            },
        },
        {
            name:     'Opacity (.)',
            element:  document.querySelector('h1 span:nth-child(3)'),
            offset:   58,
            duration: 20,
            change:   {
                opacity: {
                    from: 1,
                    to:   0,
                },
            },
        },
        {
            name:     'Opacity (s)',
            element:  document.querySelector('h1 span:nth-child(4)'),
            offset:   60,
            duration: 20,
            change:   {
                opacity: {
                    from: 1,
                    to:   0,
                },
            },
        },
        {
            name:     'Opacity (h)',
            element:  document.querySelector('h1 span:nth-child(5)'),
            offset:   62,
            duration: 20,
            change:   {
                opacity: {
                    from: 1,
                    to:   0,
                },
            },
        },
        {
            name:     'Opacity (.)',
            element:  document.querySelector('h1 span:nth-child(6)'),
            offset:   64,
            duration: 20,
            change:   {
                opacity: {
                    from: 1,
                    to:   0,
                },
            },
        },
        {
            name:     'Opacity (u)',
            element:  document.querySelector('h1 span:nth-child(7)'),
            offset:   66,
            duration: 20,
            change:   {
                opacity: {
                    from: 1,
                    to:   0,
                },
            },
        },
        {
            name:     'Opacity (a)',
            element:  document.querySelector('h1 span:nth-child(8)'),
            offset:   68,
            duration: 20,
            change:   {
                opacity: {
                    from: 1,
                    to:   0,
                },
            },
        },
    ],
}).addTrigger({
    name:    'Start',
    trigger: document.getElementById('start'),
    steps:   [
        {
            name:     'Move Content 1',
            element:  document.querySelector('.content .part:nth-child(1)'),
            offset:   20,
            duration: 60,
            change:   {
                transform: {
                    from: 'translateY(0)',
                    to:   'translateY(-100%)',
                },
            },
        },
        {
            name:     'Move Content 2',
            element:  document.querySelector('.content .part:nth-child(2)'),
            offset:   30,
            duration: 60,
            change:   {
                transform: {
                    from: 'translateY(0)',
                    to:   'translateY(-100%)',
                },
            },
        },
        {
            name:     'Move Content 3',
            element:  document.querySelector('.content .part:nth-child(3)'),
            offset:   35,
            duration: 60,
            change:   {
                transform: {
                    from: 'translateY(0)',
                    to:   'translateY(-100%)',
                },
            },
        },
    ],
}).addTrigger({
    name:    'Test',
    trigger: document.getElementById('test'),
    steps:   [
        {
            name:     'Test Appear',
            element:  document.querySelector('#test'),
            offset:   7,
            duration: 10,
            change:   {
                opacity: {
                    from: 0,
                    to:   1,
                },
            },
        },
    ],
}).build();
